import logo from './images/logo.svg';
import './css/App.css';
import React, {useState} from 'react';
import axios from "axios";

function App() {

    const [isOnline, setIsOnline] = useState("❌");
    const [isOnline2, setIsOnline2] = useState("❌");

    const checkConnection = () => {
        const response = axios.get('https://v2202403218935259584.happysrv.de:8080/utilities/isOnline');
        response.then((response) => {
            console.log("Backend ist erreichbar:" + response.data);

            if (response.data) {
                setIsOnline("✅")
            } else {
                setIsOnline("❌")
            }


        }).catch((error) => {
            setIsOnline("❌")
            console.log('Backend ist nicht erreichbar:', error);
        })
    }

    const checkConnection2 = () => {
        const response = axios.get('https://v2202403218935259584.happysrv.de:8080/utilities/isOnline2');
        response.then((response) => {
            console.log("Backend ist erreichbar:" + response.data);

            if (response.data) {
                setIsOnline2("✅")
            } else {
                setIsOnline2("❌")
            }


        }).catch((error) => {
            setIsOnline2("❌")
            console.log('Backend ist nicht erreichbar:', error);
        })
    }


    return (
        <div className="App">
            <header className="App-header">
                <img src={logo} className="App-logo" alt="logo"/>

                <button onClick={checkConnection}>Check Backend Connection.</button>
                {isOnline}
                <button onClick={checkConnection2}>Check Backend Connection2.</button>
                {isOnline2}

            </header>
        </div>
    );
}

export default App;
